import { useMemo } from 'react';

export type ItemType = {
  id?: number;
  parent?: number | null;
};

const hasParent = (items: ItemType[], id: number) => items.some((item) => item.id === id);

const useNestedList = <T extends ItemType>(items: T[]) =>
  useMemo<{ itemsById: Record<number, T>; childItemsById: Record<number, T[]>; rootItems: T[] }>(() => {
    const itemsById: Record<number, T> = {};
    const childItemsById: Record<number, T[]> = {};
    const rootItems: T[] = [];

    items.forEach((item) => {
      const { id, parent } = item;
      itemsById[id] = item;
      if (parent && hasParent(items, parent)) {
        if (!childItemsById[parent]) childItemsById[parent] = [];
        childItemsById[parent].push(item);
      } else {
        rootItems.push(item);
      }
    });

    return { itemsById, childItemsById, rootItems };
  }, [items]);

export default useNestedList;
