import { useEffect, useState } from 'react';

export const checkIfUppercase = (text: string) => {
  const total = text.match(/\S+/g);
  const uppercase = text.match(/(\b[A-ZÀÂÉÊÈËÌÏÎÔÙÛÇÆŒ][`'A-ZÀÂÉÊÈËÌÏÎÔÙÛÇÆŒ]+|\b[A-ZÀÂÉÊÈËÌÏÎÔÙÛÇÆŒ]\b)/g);
  const uppercasePercentage = Math.ceil(((uppercase?.length || 0) / (total?.length || 0)) * 100);
  const allCaps = !/[a-z]/.test(text) && /[A-ZÀÂÉÊÈËÌÏÎÔÙÛÇÆŒ]/.test(text);

  if (allCaps) {
    return true;
  }

  return total?.length >= 3 && uppercasePercentage >= 60;
};

const useUppercaseTextWarning = (text: string) => {
  const [warning, setWarning] = useState(false);

  const isUppercase = checkIfUppercase(text);

  useEffect(() => {
    const timeout = setTimeout(() => setWarning(isUppercase), isUppercase ? 1000 : 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [isUppercase]);

  return warning;
};

export default useUppercaseTextWarning;
