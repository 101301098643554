import React, { useCallback, useState } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormikContext } from 'formik';
import { useTranslation } from 'i18n';
import { addMinutes, differenceInMinutes } from 'date-fns';
import { DatePicker } from '../../common/DatePicker';
import AutocompleteTimeField from '../../common/AutocompleteTimeField';
import { DateTextField } from '../../common/FormField';
import {
  copySession,
  createSession,
  defaultStartHours,
} from '../../creatingAdminEventDetails/AdminEventSessions/utils';

export interface SessionsTypeSelectorProps {
  disabled?: boolean;
  errorText?: string;
  hasError?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  text: {
    flex: '1 1 100%',
    color: theme.palette.text.disabled,
    fontWeight: 500,
  },
  fontWeight500: {
    fontWeight: 500,
  },
  inputContainer: {
    paddingTop: 28,
    height: '100%',
  },
  lastInputContainer: {
    paddingBottom: 80,
  },
  input: {
    width: '100%',
  },
  container: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  fieldLeft: {
    paddingRight: 12,
  },
  fieldRight: {
    paddingLeft: 12,
  },
  fieldCenter: {
    paddingLeft: 12,
    paddingRight: 12,
  },
}));

const EventSessionField: React.FC<React.PropsWithChildren<SessionsTypeSelectorProps>> = (props) => {
  const { disabled, errorText, hasError } = props;
  const [t] = useTranslation('admin');
  const { classes } = useStyles();

  const [sessionDate, setSessionDate] = useState(null);
  const [sessionStartAt, setSessionStartAt] = useState(null);
  const [sessionEndAt, setSessionEndAt] = useState(null);
  const [sessions, setSessions] = useState([]);
  const hasSessions = sessions.length > 0;

  const { setFieldValue } = useFormikContext();
  const timeZone = 'America/Detroit';

  const handleChangeSessions = useCallback(
    (sessions: any[]) => {
      setSessions(sessions);
      setFieldValue('sessions', sessions);
      setSessionStartAt(sessions[0].start_at);
      setSessionEndAt(sessions[0].end_at);
    },
    [setFieldValue],
  );

  const handleDateChange = useCallback(
    (startAt: string) => {
      setSessionDate(startAt);
      if (hasSessions) {
        handleChangeSessions([copySession(sessions[0], new Date(startAt), timeZone)]);
      } else {
        handleChangeSessions([createSession(new Date(startAt), defaultStartHours)]);
      }
    },
    [handleChangeSessions, hasSessions, sessions],
  );

  const handleSessionStartAt = (value: string) => {
    setSessionStartAt(value);
    handleChangeSessions([
      {
        ...sessions[0],
        start_at: value,
        end_at: addMinutes(
          new Date(value),
          differenceInMinutes(new Date(sessions[0].end_at), new Date(sessions[0].start_at)),
        ).toISOString(),
      },
    ]);
  };

  const handleSessionEndAt = (value: string) => {
    setSessionEndAt(value);
    handleChangeSessions([
      {
        ...sessions[0],
        end_at: value,
      },
    ]);
  };

  return (
    <Grid container className={classes.inputContainer}>
      <Grid item xs={6} className={classes.fieldLeft}>
        <DatePicker
          autoOk
          disabled={disabled}
          timeZone={timeZone}
          label={t<string>('creatingAdminEventPlaceholder.field.startDate.label')}
          placeholder={t<string>('creatingAdminEventPlaceholder.field.startDate.placeholder')}
          value={sessionDate}
          onChange={handleDateChange}
          InputComponent={DateTextField}
          error={hasError}
          helperText={hasError ? errorText : ''}
        />
      </Grid>
      {sessionDate && (
        <Grid item xs={3} className={classes.fieldCenter}>
          <AutocompleteTimeField
            size="small"
            disabled={disabled}
            timeZone={timeZone}
            value={sessionStartAt}
            onChange={handleSessionStartAt}
            TextFieldProps={{
              label: t('creatingAdminEventPlaceholder.field.startTime.label'),
            }}
          />
        </Grid>
      )}
      {sessionDate && (
        <Grid item xs={3} className={classes.fieldRight}>
          <AutocompleteTimeField
            size="small"
            disabled={disabled}
            timeZone={timeZone}
            value={sessionEndAt}
            onChange={handleSessionEndAt}
            TextFieldProps={{
              label: t('creatingAdminEventPlaceholder.field.endTime.label'),
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default EventSessionField;
