import React, { useEffect } from 'react';
import { useAuthProps } from 'hooks';
import { NoSsr, Theme, useMediaQuery } from '@mui/material';
import { routes } from 'utils/routing';

import MenuItem from 'components/common/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import withUserProfileQuestionsPopover from 'components/UserProfileQuestionsPopovers/withUserProfileQuestionsPopover';
import { DetectMarketSelectors } from 'store/selectors';
import { DetectMarketActions } from 'store/actions';
import { makeStyles } from 'tss-react/mui';
import withNudgeToLoginPopover from 'components/NudgeToLoginPopover';
import { useRouter } from 'next/router';
import useMedian from 'hooks/useMedian';
import HeaderContainer from '../HeaderContainer';
import SwitchLanguage from '../SwitchLanguage';
import { HeaderDivider, HeaderLinkButton, HeaderNavigation } from '../HeaderItems';
import AuthHeaderItem from '../AuthHeaderItem';
import HeaderAvatar from '../HeaderAvatar';
import SearchHeader from '../SearchHeader';
import SwitchMarket from '../SwitchMarket';

const jliveHelpUrl = 'https://help.jlive.app/';

interface UserHeaderProps {
  hasSearch?: boolean;
  hasMarketSelect?: boolean;
  sticky?: boolean;
}

const useStyles = makeStyles()(() => ({
  stickyWrapper: {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 1000,
  },
}));

const UserHeader: React.FC<React.PropsWithChildren<UserHeaderProps>> = (props) => {
  const { sticky, hasSearch = true, hasMarketSelect = true } = props;
  const { isAuthenticated, isHardUser, isAdmin, currentUser, onLogin } = useAuthProps();
  const { classes } = useStyles();
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const router = useRouter();
  const market = useSelector(DetectMarketSelectors.getSelectedMarket);
  const { isMedian } = useMedian();

  useEffect(() => {
    if (!market) {
      dispatch(DetectMarketActions.detectMarket.request());
    }
  }, [market, dispatch]);

  useEffect(() => {
    window.onProfileButtonClick = () => {
      if (isAuthenticated) {
        const route = routes.userProfile();
        router.push(route.href);
      } else onLogin();
    };
  }, [isAuthenticated, onLogin, router]);

  const UnLoginUserHeader = [
    <SwitchLanguage menuItem={isMobile} key="lng" />,
    isMobile && <HeaderDivider key="divider" />,
    isMobile && (
      <div style={{ marginLeft: 8 }}>
        <SwitchMarket key="market" />
      </div>
    ),
    isMobile && <HeaderDivider key="divider" />,

    <HeaderLinkButton
      variant="text"
      capitalisedTitle={false}
      uppercase
      key="helpCenter"
      fullWidth={isMobile}
      menuItem={isMobile}
      href={jliveHelpUrl}
      target="_blank"
      tKey="common:button.helpCenter"
    />,
  ];

  const UserHeader = [
    isMobile && (
      <div style={{ marginLeft: 8 }}>
        <SwitchMarket key="market" />
      </div>
    ),
    isMobile && <HeaderDivider key="divider" />,
    <HeaderNavigation
      key="favoriteEvents"
      menuItem={isMobile}
      link={routes.userFavoriteEvents().link}
      tKey="common:button.favoriteEvents"
    />,
    <HeaderNavigation
      key="userTickets"
      menuItem={isMobile}
      link={routes.userTickets().link}
      tKey="common:button.userTickets"
    />,
  ];

  const UserAvatar = (
    <HeaderAvatar currentUser={currentUser}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {(onClose) => [
        isHardUser && (
          <HeaderNavigation
            key="userProfile"
            menuItem
            link={routes.userProfile().link}
            tKey="common:button.userProfile"
          />
        ),
        <HeaderNavigation
          key="helpCenter"
          menuItem
          link={jliveHelpUrl}
          target="_blank"
          tKey="common:button.helpCenter"
        />,
        <AuthHeaderItem key="authHeaderItem" onClose={onClose} />,
        isAdmin && (
          <MenuItem key="switchToAdmin">
            <HeaderLinkButton fullWidth href={routes.adminHome().href} tKey="common:button.switchToAdmin" />
          </MenuItem>
        ),
      ]}
    </HeaderAvatar>
  );

  return (
    <NoSsr>
      <div className={sticky && classes.stickyWrapper}>
        <HeaderContainer
          right={isAuthenticated ? UserHeader : UnLoginUserHeader}
          avatar={isAuthenticated ? UserAvatar : <AuthHeaderItem />}
          search={hasSearch && !isMedian ? <SearchHeader /> : null}
          left={hasMarketSelect && !isMobile ? <SwitchMarket key="market" /> : null}
        />
      </div>
    </NoSsr>
  );
};

export default withNudgeToLoginPopover(withUserProfileQuestionsPopover(UserHeader));
