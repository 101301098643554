import React from 'react';
import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';

import { useTranslation } from 'i18n';
import { useRouter } from 'next/router';
import { makeStyles } from 'tss-react/mui';
import { routes } from 'utils/routing';
import { useSelector } from 'react-redux';
import EventItem from './EventItem';
import Button from '../../Button';
import { MARKETS_SLUGS_MAP } from '../../../../utils/transform';
import { DetectMarketSelectors } from '../../../../store/selectors';

interface EventListProps {
  entities: any[];
  count: number;
  searchTerm: string;
  onClose: () => void;
}

const useStyles = makeStyles()((theme) => ({
  eventsInfo: {
    padding: '12px 0 !important',
  },
  infoContainer: {
    padding: '0 !important',
  },
  itemsWrapper: {
    padding: '12px 0 !important',
  },
  items: {
    padding: 0,
    alignItems: 'stretch',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    gap: '16px 76px',
  },
  item: {
    width: '100%',
    display: 'flex',
    alignSelf: 'stretch',
    padding: '0 !important',
    maxWidth: 'calc(50% - 38px)',
    flexBasis: 'unset',
    [theme.breakpoints.down('md')]: {
      maxWidth: 520,
    },
  },
  total: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
}));

const EventsList: React.FC<React.PropsWithChildren<EventListProps>> = (props) => {
  const { entities, count, searchTerm, onClose } = props;
  const [t] = useTranslation('public');
  const router = useRouter();
  const { classes } = useStyles();
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const isLessSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'), { noSsr: true });

  const market = useSelector(DetectMarketSelectors.getSelectedMarket);

  const onViewMoreEventsClick = () => {
    onClose();
    const route = routes.events(
      searchTerm && {
        search: searchTerm,
        ordering: 'session_date',
        market_slug: MARKETS_SLUGS_MAP[market as keyof typeof MARKETS_SLUGS_MAP],
      },
    );
    router.push(route.href);
  };
  const itemsCount = () => (isLessSm ? 4 : 6);
  return (
    !!entities?.length && (
      <Grid container spacing={3}>
        <Grid item className={classes.infoContainer} xs={12}>
          <Grid container className={classes.eventsInfo} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography className={classes.total} color="textSecondary" variant="body2">
                {count === 1
                  ? `${count} ${t<string>('searchHeader.events.title')}`
                  : `${count} ${t<string>('searchHeader.events.titlePlural')}`}
              </Typography>
            </Grid>
            {!isMobile && count > itemsCount() && (
              <Grid item>
                <Button rounded color="primary" size="small" variant="outlined" href="" onClick={onViewMoreEventsClick}>
                  {t<string>('searchHeader.events.viewMoreResults').toUpperCase()}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item className={classes.itemsWrapper} xs={12}>
          <Grid className={classes.items} container xs={12}>
            {entities.slice(0, itemsCount()).map((event) => (
              <Grid className={classes.item} key={event.id} item xs={12} sm={12} md={5} lg={5}>
                <EventItem event={event} onRedirect={onClose} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        {isMobile && count > itemsCount() && (
          <Grid item xs>
            <Grid container justifyContent="center">
              <Grid item>
                <Button rounded color="primary" size="small" variant="outlined" href="" onClick={onViewMoreEventsClick}>
                  {t<string>('searchHeader.events.viewMoreResults').toUpperCase()}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  );
};

export default EventsList;
