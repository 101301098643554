import React from 'react';
import { useTranslation } from 'i18n';

import Button from 'components/common/Button';
import MenuItem from 'components/common/MenuItem';
import { makeStyles } from 'tss-react/mui';
import HeaderLinkItem from './HeaderLinkItem';

interface HeaderLinkButtonProps {
  menuItem?: boolean;
  href: any;
  variant?: 'outlined' | 'text' | 'contained';
  tKey: string;
  target?: string;
  fullWidth: boolean;
  capitalisedTitle?: boolean;
  uppercase?: boolean;
}

const useStyles = makeStyles()(() => ({
  noTransform: {
    textTransform: 'none',
  },
  uppercase: {
    textTransform: 'uppercase',
    fontWeight: 500,
    height: 40,
    padding: '5px 14px 4px',
  },
}));

const HeaderLinkButton: React.FC<React.PropsWithChildren<HeaderLinkButtonProps>> = (props) => {
  const { menuItem, target, href, tKey, variant = 'outlined', capitalisedTitle = true, fullWidth, uppercase } = props;
  const [t] = useTranslation();
  const { classes, cx } = useStyles();

  return menuItem ? (
    <MenuItem>
      <HeaderLinkItem href={href} target={target} style={{ marginLeft: 8 }} label={t(tKey)} />
    </MenuItem>
  ) : (
    <Button
      className={cx({ [classes.noTransform]: !capitalisedTitle, [classes.uppercase]: uppercase })}
      fullWidth={fullWidth}
      href={href}
      target={target}
      variant={variant}
      rounded
      size="small"
      color="primary"
    >
      {t(tKey)}
    </Button>
  );
};

export default HeaderLinkButton;
