import React, { useEffect, useState } from 'react';
import { DialogContent, Grid, TextField } from '@mui/material';
import dynamic from 'next/dynamic';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'i18n';
import { makeStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Button from '../../common/Button';
import StickyBottomContent from '../../common/StickyBottomContent';
import { FieldToTextField } from '../../common/FormField';
import { OrgTypesActions } from '../../../store/actions';
import { emailSchema } from '../../../utils/validation';
import Link from '../../common/Link';

import EventSessionField from './EventSessionField';
import LimitedTextField from '../../common/LimitedTextField';
import { useAuthProps } from '../../../hooks';

const TextEditor = dynamic(() => import('components/common/TextEditor'), { ssr: false });

interface IProps {
  onClose: () => void;
  onSubmit: (values: any) => void;
  disabled?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  footer: {
    height: 88,
    borderTop: `1px solid ${theme.palette.custom.border.gray}`,
  },
  inputContainer: {
    paddingTop: 28,
    height: '100%',
  },
  lastInputContainer: {
    paddingBottom: 80,
  },
  input: {
    width: '100%',
  },
  container: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  fieldLeft: {
    paddingRight: 12,
  },
  fieldRight: {
    paddingLeft: 12,
  },
  fieldCenter: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  helperText: {
    position: 'absolute',
    right: 0,
  },
}));

const InputLabelProps = {
  shrink: true,
};

const CreateEventPlaceholderModal: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { onClose, disabled, onSubmit } = props;
  const { currentUser } = useAuthProps();
  const [t] = useTranslation('admin');
  const dispatch = useDispatch();
  const { classes, cx } = useStyles();

  const [hasDescription, setDescription] = useState(false);

  useEffect(() => {
    dispatch(OrgTypesActions.getOrgTypes.request());
  }, [dispatch]);

  const validationSchema = Yup.object({
    title: Yup.string().trim().max(100).required(t('common:field.validation.mixed.required')),
    description: Yup.string().min(150).max(3000),
    contact_name: Yup.string().max(250),
    contact_email: emailSchema(t('common:field.validation.string.email')),
    sessions: Yup.array().min(1, t('common:field.validation.mixed.required')),
  });

  const fullName = () => {
    let fullName = '';
    if (currentUser.first_name?.length > 0) fullName = currentUser.first_name;
    if (currentUser.last_name?.length > 0) {
      if (fullName) fullName = fullName.concat(` ${currentUser.last_name}`);
      else fullName = currentUser.last_name;
    }
    return fullName;
  };

  const initialValues = {
    title: '',
    sessions: [] as any[],
    contact_name: fullName(),
    contact_email: currentUser.email,
    description: '',
  };

  const onAddDescription = () => {
    setDescription(true);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ touched, errors }) => (
        <Form placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <DialogContent>
            <Grid container className={classes.container}>
              <Grid xs={12}>
                <Field
                  fullWidth
                  name="title"
                  variant="outlined"
                  placeholder={t<string>('creatingAdminEventPlaceholder.field.eventTitle.placeholder')}
                  label={t<string>('creatingAdminEventPlaceholder.field.eventTitle.label')}
                  disabled={disabled}
                  component={FieldToTextField}
                  TextField={LimitedTextField}
                  error={touched.title && errors.title ? errors.title : ''}
                  maxLength={100}
                  InputLabelProps={InputLabelProps}
                  rows={1}
                />
              </Grid>
              <Grid xs={12}>
                <EventSessionField
                  errorText={String(errors.sessions)}
                  hasError={Boolean(touched.sessions && errors.sessions)}
                />
              </Grid>

              <Grid item xs={6} className={cx(classes.inputContainer, classes.fieldLeft)}>
                <Field
                  fullWidth
                  name="contact_name"
                  variant="outlined"
                  placeholder={t<string>('creatingAdminEventPlaceholder.field.contact_name.label')}
                  label={t<string>('creatingAdminEventPlaceholder.field.contact_name.label')}
                  disabled={disabled}
                  component={FieldToTextField}
                  TextField={TextField}
                  error={touched.contact_name && errors.contact_name ? errors.contact_name : ''}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>
              <Grid item xs={6} className={cx(classes.inputContainer, classes.fieldRight)}>
                <Field
                  fullWidth
                  name="contact_email"
                  variant="outlined"
                  placeholder={t<string>('creatingAdminEventPlaceholder.field.contact_email.label')}
                  label={t<string>('creatingAdminEventPlaceholder.field.contact_email.label')}
                  disabled={disabled}
                  component={FieldToTextField}
                  TextField={TextField}
                  error={touched.contact_email && errors.contact_email ? errors.contact_email : ''}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>
              {hasDescription ? (
                <Grid item xs={12} className={classes.inputContainer}>
                  <Field
                    maxLength={3000}
                    name="description"
                    label={`${t<string>('common:field.label.description')}`}
                    disabled={disabled}
                    component={FieldToTextField}
                    TextField={TextEditor}
                  />
                </Grid>
              ) : (
                <Grid item xs className={classes.inputContainer}>
                  <Link
                    type="button"
                    component="button"
                    variant="button"
                    color="primary"
                    underline="none"
                    disabled={disabled}
                    startIcon={<AddBoxIcon fontSize="small" />}
                    onClick={onAddDescription}
                  >
                    {t<string>('creatingAdminEvent.eventOrderItemForm.button.addDescription')}
                  </Link>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <StickyBottomContent position="sticky">
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button rounded variant="outlined" color="secondary" onClick={onClose}>
                  {t<string>('common:button.cancel')}
                </Button>
              </Grid>
              <Grid item>
                <Button rounded variant="contained" color="secondary" type="submit">
                  {t<string>('common:button.submitToCalendar')}
                </Button>
              </Grid>
            </Grid>
          </StickyBottomContent>
        </Form>
      )}
    </Formik>
  );
};

export default CreateEventPlaceholderModal;
