import { alpha } from '@mui/material/styles';
import palette from '../palette';
import defaultOverrides from '../overrides';
import typography from '../typography';

const overrides = {
  ...defaultOverrides,
  MuiOutlinedInput: {
    root: {
      '&:focus-visible': {
        backgroundColor: 'unset',
      },
      '&$focused': {
        backgroundColor: 'unset',
      },
    },
    input: {
      padding: '10.5px 8px',
    },
    notchedOutline: {
      border: 'none',
    },
  },
  MuiSelect: {
    selectMenu: {
      minHeight: 'unset',
    },
    select: {
      '&:focus-visible': {
        backgroundColor: 'unset',
      },
    },
    root: {
      color: palette.custom.green.main,
      ...typography.body2,
    },
    icon: {
      color: palette.custom.green.main,
    },
  },
  MuiTypography: {
    body1: {
      fontWeight: 'inherit' as const,
    },
  },
  MuiCheckbox: {
    colorPrimary: {
      color: palette.custom.green.main,
    },
  },
  MuiSvgIcon: {
    colorPrimary: {
      color: palette.custom.green.main,
    },
  },
  MuiMenuItem: {
    root: {
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
  MuiListItem: {
    root: {
      '&:focus-visible': {
        backgroundColor: 'unset',
      },
      '&$selected': {
        backgroundColor: 'unset',
        '&:hover': {
          backgroundColor: alpha(palette.custom.green.main, 0.2),
          color: palette.custom.green.main,
        },
      },
    },
    button: {
      borderRadius: 8,
      '&:hover': {
        backgroundColor: alpha(palette.custom.green.main, 0.2),
        color: palette.custom.green.main,
      },
    },
  },
};

export default overrides;
