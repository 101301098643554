import React, { forwardRef, useImperativeHandle, useState } from 'react';

interface IComponentProps {
  open: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

interface IProps {
  onBlur?: () => void;
}

const withDatePicker = <T extends IComponentProps>(Component: React.ComponentType<React.PropsWithChildren<T>>) =>
  forwardRef<any, IProps & Omit<T, keyof IComponentProps>>((props, ref) => {
    // TODO:
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { onBlur } = props;
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      Promise.resolve().then(() => {
        if (onBlur) onBlur();
      });
    };

    useImperativeHandle(ref, () => ({
      open: handleOpen,
    }));

    return (
      <Component
        // TODO:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        {...(props as T)}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
      />
    );
  });

export default withDatePicker;
