import React, { useEffect, useState } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import Image from 'next/legacy/image';
import { useModal } from 'hooks';
import { useSelector } from 'react-redux';
import { DetectMarketSelectors } from 'store/selectors';
import SearchHeaderField from './SearchHeaderField';
import SearchModal from './SearchModal';

const SearchHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const [inputValue, setInputValue] = useState('');
  const [showSearchModal] = useModal(SearchModal);
  const market = useSelector(DetectMarketSelectors.getSelectedMarket);

  useEffect(() => {
    window.onSearchEventsClick = () => {
      showSearchModal({ search: inputValue, marketSlug: market });
    };
  }, [inputValue, market, showSearchModal]);

  const handleDialogClose = () => {
    setInputValue('');
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const { value } = event.target;
    setInputValue(value);
    if (value.length > 2) {
      showSearchModal({ search: value, marketSlug: market, onClose: handleDialogClose() });
    }
  };

  const handleOnClick = () => {
    showSearchModal({ search: inputValue, marketSlug: market });
  };

  return isMobile ? (
    <Image src="/static/img/icon-search.png" alt="icon-search" width={24} height={24} onClick={handleOnClick} />
  ) : (
    <SearchHeaderField inputValue={inputValue} onChange={handleOnChange} />
  );
};

export default SearchHeader;
