import React, { memo, useRef, useState } from 'react';
import { CircularProgress, Grid, ListItem, ListItemText, SelectProps } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { TFunction } from 'next-i18next';
import useNestedList from 'hooks/useNestedList';
import headerSelect from 'theme/headerSelect';

import { HeaderList, HeaderSelect } from '../HeaderItems';
import AdminMarketSelect from '../AdminMarketSelect';

interface IProps {
  chosenOrganization: any;
  t: TFunction;
  onSelect: (id?: number) => void;
  options: any[];
  organizationId: number;
  isFetching: boolean;
  market: string;
  markets: any[];
  onFilterChange: (id: string) => void;
}

export type ItemType = {
  id: number;
  title: string;
  parent: number | null;
};

const useStyles = makeStyles()((theme) => ({
  icon: {
    margin: 'auto',
  },
  text: {
    fontWeight: theme.typography.fontWeightMediumBold,
  },
  wrapper: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  listItem: {
    '&:hover': {
      color: '#5FC6B9',
      borderRadius: 8,
      backgroundColor: 'rgba(95, 198, 185, 0.2)',
    },
  },
}));

const getParentIds = (options: ItemType[], ids: number[]): number[] => {
  if (!ids.length) return [];
  const parentIds: number[] = [];
  ids.forEach((id) => {
    const option = options.find((option) => option.id === id);
    if (option?.parent) parentIds.push(option.parent);
  });
  return [...parentIds, ...getParentIds(options, parentIds)];
};

const getOptions = (options: ItemType[], value: string) => {
  if (!value) return options;
  const optionIds: number[] = [];
  options.forEach((option) => {
    if (option.title.toLowerCase().includes(value.toLowerCase())) optionIds.push(option.id);
  });
  const parentIds = getParentIds(options, optionIds);
  const filteredIds = new Set([...optionIds, ...parentIds]);
  return options.filter((option) => filteredIds.has(option.id));
};

const AdminOrganizationsSelect: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { t, onSelect, options, chosenOrganization, organizationId, markets, market, onFilterChange, isFetching } =
    props;
  const [value, setValue] = useState('');
  const inputEl = useRef(null);
  const { classes } = useStyles();

  const { childItemsById, rootItems } = useNestedList<ItemType>(getOptions(options, value));
  const handleChange: SelectProps['onChange'] = (event) => {
    onFilterChange(event.target.value as string);
    inputEl.current.click();
  };

  return (
    <Grid container spacing={2} alignItems="center" className={classes.wrapper}>
      <Grid item>
        <AdminMarketSelect isFetching={isFetching} t={t} options={markets} value={market} onChange={handleChange} />
      </Grid>
      <Grid item>
        <HeaderSelect
          inputEl={inputEl}
          title={
            organizationId
              ? chosenOrganization?.title || <CircularProgress size={18} className={classes.icon} />
              : t('adminHeader.allOrganizations')
          }
          onSearch={setValue}
        >
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {(onClose) => {
            const handleClick = () => {
              onClose();
              onSelect();
            };
            return (
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={headerSelect}>
                  <HeaderList
                    color="#5FC6B9"
                    rootItems={rootItems}
                    childItemsById={childItemsById}
                    onSelect={onSelect}
                    onClose={onClose}
                    bold
                    opened={Boolean(value)}
                  >
                    {!value && rootItems.length > 1 && (
                      <ListItem button onClick={handleClick} className={classes.listItem}>
                        <ListItemText
                          classes={{ primary: classes.text }}
                          primary={t<string>('adminHeader.allOrganizations')}
                        />
                      </ListItem>
                    )}
                  </HeaderList>
                </ThemeProvider>
              </StyledEngineProvider>
            );
          }}
        </HeaderSelect>
      </Grid>
    </Grid>
  );
};

export default memo(AdminOrganizationsSelect);
