import { addHours, addSeconds, differenceInSeconds } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { EventSessionEdit } from 'api/admin/models';

export const defaultDifferenceInHours = 3;
export const defaultStartHours = 18;

export const createSession = (date: Date, startHours = 0): EventSessionEdit => ({
  start_at: addHours(date, startHours).toISOString(),
  end_at: addHours(date, startHours + defaultDifferenceInHours).toISOString(),
});

export const setZonedDate = (source: Date | string | number, target: Date | string | number, timeZone: string) => {
  const zonedSource = utcToZonedTime(source, timeZone);
  const zonedTarget = utcToZonedTime(target, timeZone);
  zonedTarget.setHours(zonedSource.getHours(), zonedSource.getMinutes());
  return zonedTimeToUtc(zonedTarget, timeZone);
};

export const copySession = (session: EventSessionEdit, date: Date | string | number, timeZone: string) => {
  const diff = differenceInSeconds(new Date(session.end_at), new Date(session.start_at));
  const startAt = setZonedDate(session.start_at, date, timeZone);
  const endAt = addSeconds(startAt, diff);

  return {
    start_at: startAt.toISOString(),
    end_at: endAt.toISOString(),
  };
};
