import React from 'react';
import { isValid, parseISO } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import AutocompleteTimePicker, { Option, AutocompleteTimePickerProps } from './AutocompleteTimePicker';

export type AutocompleteTimeFieldProps = Omit<AutocompleteTimePickerProps, 'value' | 'onChange'> & {
  timeZone?: string;
  value: string;
  onChange: (value: string) => void;
};

const parseValue = (value: string, timeZone: string): Option => {
  const date = utcToZonedTime(value, timeZone);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return {
    hours: String(hours % 12 || 12),
    minutes: String(minutes),
    period: hours >= 12 ? 'PM' : 'AM',
  };
};

const AutocompleteTimeField: React.FC<React.PropsWithChildren<AutocompleteTimeFieldProps>> = (props) => {
  const { timeZone = 'UTC', value, disabled, onChange, ...rest } = props;
  const isValidValue = value && isValid(parseISO(value));

  const handleChange: AutocompleteTimePickerProps['onChange'] = (option) => {
    const date = utcToZonedTime(value, timeZone);
    date.setHours((Number(option.hours) % 12) + (option.period === 'PM' ? 12 : 0), Number(option.minutes));
    onChange(zonedTimeToUtc(date, timeZone).toISOString());
  };

  return (
    <AutocompleteTimePicker
      {...rest}
      disabled={disabled || !isValidValue}
      value={isValidValue ? parseValue(value, timeZone) : null}
      onChange={handleChange}
    />
  );
};

export default AutocompleteTimeField;
