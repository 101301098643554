import React from 'react';
import { makeStyles } from 'tss-react/mui';

const breakpoints = ['xs', 'sm', 'md'] as const;
const sizes = [30, 40, 48, 64, 80, 88, 96, 136, 160];

interface IProps {
  children: React.ReactNode;
  className?: string;
  xs: number;
  sm: number;
  md: number;
}

const useStyles = makeStyles()((theme) => {
  const styles: any = {
    container: {
      borderRadius: '8px',
      overflow: 'hidden',
    },
  };

  for (let i = 0; i < breakpoints.length; i += 1) {
    for (let j = 0; j < sizes.length; j += 1) {
      const breakpoint = breakpoints[i];
      const size = sizes[j];

      styles[`${breakpoint}-${size}`] = {
        [theme.breakpoints.up(breakpoint)]: {
          width: size,
          height: size,
        },
      };
    }
  }

  return styles;
});

const Logo: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { xs = sizes[0], sm, md, className, children } = props;
  const { classes, cx }: any = useStyles();
  return (
    <div className={cx(classes.container, className, classes[`xs-${xs}`], classes[`sm-${sm}`], classes[`md-${md}`])}>
      {children}
    </div>
  );
};

export default Logo;
