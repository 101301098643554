import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IProps {
  onClose: () => void;
  className?: any;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    color: '#7B8BAD',
    position: 'absolute',
    top: theme.spacing(9),
    right: theme.spacing(5),
    left: 'unset',
    [theme.breakpoints.down('md')]: {
      color: '#4E6DF5',
      top: theme.spacing(1),
      left: theme.spacing(1),
      right: 'unset',
    },
  },
}));

const CloseButton: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { onClose, className } = props;
  const { classes, cx } = useStyles();
  return (
    <IconButton size="small" className={cx(classes.root, className)} onClick={onClose}>
      <CloseIcon fontSize="inherit" />
    </IconButton>
  );
};

export default CloseButton;
