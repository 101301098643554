import React from 'react';
import { makeStyles } from 'tss-react/mui';

export type Position = 'static' | 'relative' | 'fixed' | 'absolute' | 'sticky';
export type JustifyContent = 'start' | 'center' | 'space-between' | 'space-around' | 'space-evenly';

interface IProps {
  className?: string;
  children: React.ReactNode;
  position?: Position;
  justifyContent?: JustifyContent;
}

type StylePropsType = {
  position?: Position;
  justifyContent?: JustifyContent;
};

const useStyles = makeStyles<StylePropsType>()((_, props) => ({
  container: {
    position: props.position,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: props.justifyContent,
  },
}));

const StickyBottom: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { children, className, ...rest } = props;
  const { classes, cx } = useStyles(rest, {
    props: rest,
  });
  return <div className={cx(classes.container, className)}>{children}</div>;
};

export default StickyBottom;
