import React from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { routes } from 'utils/routing';
import { useAuthProps } from 'hooks';

import MenuItem from 'components/common/MenuItem';
import { useSelector } from 'react-redux';
import { AdminMarketsSelectors, AdminOrganizationsSelectors, AuthSelectors } from 'store/selectors';
import { RootState } from 'store/rootReducer';
import HeaderContainer from '../HeaderContainer';
import { HeaderDivider, HeaderLinkButton, HeaderNavigation } from '../HeaderItems';
import { AdminOrganizationsSelectContainer } from '../AdminOrganizationsSelect';
import AuthHeaderItem from '../AuthHeaderItem';
import HeaderAvatar from '../HeaderAvatar';
import OrganizationsSubheader from './OrganizationsSubheader';
import { makeEntitiesLoader } from '../../../../hooks/makeSimpleLoader';
import { AdminOrganizationsActions } from '../../../../store/actions';

interface AdminHeaderProps {
  organizationId?: number;
  isSuperAdminArea?: boolean;
}

const jliveHelpUrl = 'https://help.jlive.app/';

const useAdminOrganizationsState = makeEntitiesLoader(
  AdminOrganizationsActions.getAdminAllOrganizations,
  AdminOrganizationsSelectors.adminAllOrganizationsState,
  false,
);

const AdminHeader: React.FC<React.PropsWithChildren<AdminHeaderProps>> = ({
  organizationId,
  isSuperAdminArea = false,
}) => {
  const { isSuperAdmin, currentUser } = useAuthProps();
  const isMarketAdmin = useSelector(AuthSelectors.isMarketAdmin);
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const chosenOrganization = useSelector((state: RootState) =>
    AdminOrganizationsSelectors.allOrganizationsById(state, organizationId),
  );
  const marketFromOrg = useSelector((state: RootState) =>
    AdminMarketsSelectors.adminMarketById(state, Number(chosenOrganization?.market_id)),
  );

  const allOrganizationState = useAdminOrganizationsState();

  const AdminHeader = (isSuperAdmin || allOrganizationState.entities.length > 0) && [
    !isSuperAdminArea && <AdminOrganizationsSelectContainer key="org" />,
    isMobile && <HeaderDivider key="divider" />,
    organizationId && (
      <HeaderNavigation
        key="events"
        menuItem={isMobile}
        link={routes.adminOrganizationEvents(organizationId).link}
        tKey="common:button.events"
      />
    ),
    marketFromOrg && <OrganizationsSubheader market={marketFromOrg} />,
  ];

  const AdminAvatar = (
    <HeaderAvatar currentUser={currentUser}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {(onClose) => [
        (isSuperAdmin || isMarketAdmin) && isSuperAdminArea && (
          <HeaderNavigation key="button" menuItem link={routes.adminAllEvents().link} tKey="common:button.orgAdmin" />
        ),
        (isSuperAdmin || isMarketAdmin) && !isSuperAdminArea && (
          <HeaderNavigation
            key="button"
            menuItem
            link={routes.adminManageAdmins().link}
            tKey="common:button.platformAdmin"
          />
        ),

        <HeaderNavigation
          key="helpCenter"
          menuItem
          link={jliveHelpUrl}
          target="_blank"
          tKey="common:button.helpCenter"
        />,
        <AuthHeaderItem key="auth" onClose={onClose} />,
        <MenuItem key="switchToUser">
          <HeaderLinkButton fullWidth href={routes.home().href} tKey="common:button.switchToUser" />
        </MenuItem>,
      ]}
    </HeaderAvatar>
  );

  return <HeaderContainer right={AdminHeader} avatar={AdminAvatar} />;
};

export default AdminHeader;
