export function trackButtonClick(name: string, shouldTrack = true) {
  if (shouldTrack) {
    const trackButton = () => {
      try {
        (window as any).mixpanel?.track('Button Clicked', {
          'Button Details:': name,
        });
      } catch (error) {
        console.log('error', error);
      }
    };
    trackButton();
  }
}
