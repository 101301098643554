import Image from 'next/legacy/image';
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'i18n';

const useStyles = makeStyles()((theme) => ({
  emptyImage: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 40,
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 16,
  },
  textFirstPart: {
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
      fontWeight: '600',
    },
  },
  textSecondPart: {
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
      fontWeight: '400',
    },
  },
}));

const EmptyState: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { classes } = useStyles();
  const [t] = useTranslation('public');

  return (
    <Grid container>
      <Grid item xs={12} className={classes.emptyImage}>
        <Image src="/static/img/search-empty-state.png" alt="search-empty-state" width={225} height={228} />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} className={classes.textContainer}>
            <Typography className={classes.textFirstPart} align="center" variant="h5" color="textSecondary">
              {t<string>('searchHeader.emptyState.firstPart')}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.textContainer}>
            <Typography className={classes.textSecondPart} align="center" variant="h6" color="textSecondary">
              {t<string>('searchHeader.emptyState.secondPart')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EmptyState;
