import React from 'react';
import { useTranslation } from 'i18n';
import { useAuthProps } from 'hooks';

import Button from 'components/common/Button';
import MenuItem from 'components/common/MenuItem';
import { makeStyles } from 'tss-react/mui';
import { HeaderLinkItem } from './HeaderItems';

interface AuthHeaderItemProps {
  onClose?: () => void;
}

const useStyles = makeStyles()(() => ({
  loginButton: {
    textTransform: 'uppercase',
    minWidth: 85,
    fontWeight: 500,
  },
}));

const AuthHeaderItem: React.FC<React.PropsWithChildren<AuthHeaderItemProps>> = (props) => {
  const { onClose } = props;
  const [t] = useTranslation();
  const { isAuthenticated, onLogin, onLogout } = useAuthProps();
  const { classes } = useStyles();

  const onHadleOnLogin = () => {
    document?.getElementById('NudgeToLoginPopover')?.remove();
    onLogin();
  };

  const onClick = () => {
    onLogout();
    onClose();
  };

  if (!isAuthenticated) {
    return (
      <Button
        className={classes.loginButton}
        rounded
        size="small"
        variant="contained"
        color="primary"
        onClick={onHadleOnLogin}
      >
        {t<string>('common:button.login')}
      </Button>
    );
  }

  return (
    <MenuItem onClick={onClick}>
      <HeaderLinkItem label={t<string>('common:button.logout')} component="button" color="secondary" />
    </MenuItem>
  );
};

export default AuthHeaderItem;
