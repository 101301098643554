import React, { useCallback, useState } from 'react';
import { useTranslation } from 'i18n';
import { Theme, useMediaQuery } from '@mui/material';
import useModal from 'hooks/useModal';

import MenuItem from 'components/common/MenuItem';
import Button from 'components/common/Button';
import CreateEventModalContainer from 'components/admin/CreateEventModalContainer';
import { makeStyles } from 'tss-react/mui';
// eslint-disable-next-line max-len
import CreateEventPlaceholderModalContainer from 'components/modals/CreateEventPlaceholderModal/CreateEventPlaceholderModalContainer';
import { CalendarSettingsSelectors } from 'store/selectors';
import { makeEntityLoader } from 'hooks/makeSimpleLoader';
import { CalendarSettingsActions } from 'store/actions';
import { HeaderMenu } from '../HeaderItems';

interface AdminHeaderProps {
  market: any;
}

const useStyles = makeStyles()((theme) => ({
  menuItem: {
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '160%',
    color: theme.palette.text.primary,
    '&:hover': {
      color: '#4E6DF5',
      backgroundColor: 'rgba(78, 109, 245, 0.07)',
    },
  },
}));

const useCalendarSettingsState = makeEntityLoader(
  CalendarSettingsActions.getCalendarSettings,
  CalendarSettingsSelectors.calendarSettingsStateById,
);

const OrganizationsSubheader: React.FC<React.PropsWithChildren<AdminHeaderProps>> = ({ market }) => {
  const { classes } = useStyles();
  const [t] = useTranslation();
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const ccSettingsState = useCalendarSettingsState(
    () => [market.id, { marketSlug: market.slug }],
    [market.id, market.slug],
  );
  const isCC = ccSettingsState.entity?.jcalendar.is_community_calendar;
  const [showCreateEventModal] = useModal(CreateEventModalContainer);
  const [showCreateEventPlaceholderModal] = useModal(CreateEventPlaceholderModalContainer);
  const handleCreateEventClick = (e: React.MouseEvent) => {
    if (isCC) {
      setAnchorEl(e.currentTarget);
    } else {
      showCreateEventModal();
    }
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleCreateEvent = useCallback(() => {
    handleClose();
    showCreateEventModal();
  }, [showCreateEventModal, handleClose]);

  const handleCreatePlaceholderEvent = useCallback(() => {
    handleClose();
    showCreateEventPlaceholderModal({
      exclude_from_calendar_before_event: ccSettingsState.entity?.jcalendar.exclude_from_calendar_before_event,
    });
  }, [
    showCreateEventPlaceholderModal,
    handleClose,
    ccSettingsState.entity?.jcalendar.exclude_from_calendar_before_event,
  ]);

  return (
    <div>
      <Button
        key="create"
        fullWidth
        color="primary"
        size="small"
        rounded
        variant={isMobile ? 'outlined' : 'contained'}
        onClick={handleCreateEventClick}
      >
        {t<string>('common:button.create')}
      </Button>
      <HeaderMenu open={open} onClose={handleClose} anchorEl={anchorEl}>
        <MenuItem key="create.event" onClick={handleCreateEvent} className={classes.menuItem}>
          {t(`common:button.createEvent`)}
        </MenuItem>
        <MenuItem key="create.event-modal" onClick={handleCreatePlaceholderEvent} className={classes.menuItem}>
          {t(`common:button.createEventPlaceholder`)}
        </MenuItem>
      </HeaderMenu>
    </div>
  );
};

export default OrganizationsSubheader;
