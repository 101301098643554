import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SuccessfullyCreatedAccountPopoverContainer from './SuccessfullyCreatedAccountPopoverContainer';

const useStyles = makeStyles()((theme) => ({
  popup: {
    position: 'absolute',
    top: 82,
    zIndex: 300,
    right: 'calc((100vw - 288px) / 2)',
    [theme.breakpoints.up('sm')]: {
      right: 40,
      '&::before': {
        color: '#fff',
        top: -24,
        right: 24,
        content: '"▲"',
        fontSize: 24,
        position: 'absolute',
        width: 24,
        height: 22,
      },
    },
  },
}));

interface IProps {
  onClose: any;
}

const SuccessfullyCreatedAccountPopover: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.popup}>
      <SuccessfullyCreatedAccountPopoverContainer {...props} />
    </Box>
  );
};

export default SuccessfullyCreatedAccountPopover;
