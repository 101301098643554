import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useRouter } from 'next/router';
import { routes } from 'utils/routing';
import Image from 'components/common/Image';
import Logo from 'components/common/Logo';

interface OrganizationProps {
  organization: any;
  onRedirect: () => void;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: '0 0 8px 0',
    borderBottom: `1px solid ${theme.palette.custom.gray.medium}`,
    height: 64,
    cursor: 'pointer',
  },
  imageWrapper: {
    width: 64,
    padding: '0 !important',
  },
  imageContainer: {
    position: 'relative',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 64px)',
    height: 52,
  },
  title: {
    fontWeight: 500,
  },
  icons: {
    display: 'flex',
  },
  dateText: {
    fontWeight: 600,
  },
}));

const OrganizationItem: React.FC<React.PropsWithChildren<OrganizationProps>> = (props) => {
  const { organization, onRedirect } = props;
  const { title, logo } = organization;
  const { classes } = useStyles();
  const router = useRouter();
  const onClick = () => {
    onRedirect();
    const route = routes.organizationProfile(organization.id);
    router.push(route.href);
  };

  return (
    <Grid className={classes.root} container alignItems="center" onClick={onClick}>
      <Grid item className={classes.imageWrapper}>
        <div className={classes.imageContainer}>
          <Logo xs={48} sm={48} md={48}>
            <Image src={logo} alt={logo} />
          </Logo>
        </div>
      </Grid>
      <Grid item className={classes.infoContainer}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.title} color="primary" variant="body2" component="p">
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrganizationItem;
