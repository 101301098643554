import React, { memo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox, CheckboxProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IProps extends CheckboxProps {
  arrowColor?: string;
  IconComponent?: React.ElementType;
}

interface StyleProps {
  arrowColor?: string;
}

const useStyles = makeStyles<StyleProps>()((theme, props: StyleProps) => ({
  root: {
    color: props?.arrowColor || 'unset',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    '&.checked': {
      transform: 'rotate(180deg)',
    },
  },
}));

const ExpandIconCheckbox: React.FC<React.PropsWithChildren<IProps>> = ({
  arrowColor,
  checked,
  IconComponent = ExpandMoreIcon,
  color = 'primary',
  ...props
}) => {
  const { classes, cx } = useStyles({ arrowColor });
  const icon = <IconComponent color={arrowColor || ''} className={cx(classes.root, { checked })} />;

  return <Checkbox color={color} icon={icon} checkedIcon={icon} checked={checked} {...props} />;
};

export default memo(ExpandIconCheckbox);
