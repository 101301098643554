import React, { useEffect, useMemo, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogProps,
  Grid,
  Input,
  InputAdornment,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'i18n';
import Image from 'next/legacy/image';
import CustomDialogTitle from 'components/common/CustomDialogTitle';
import { EventsSelectors, OrganizationsSelectors } from 'store/selectors';
import { EventsActions, OrganizationsActions } from 'store/actions';
import { isFetchingStates } from 'store/utils';
import { useLoader } from 'hooks';
import RenderComponent from 'components/common/RenderComponent';
import EventsList from './EventsList';
import EmptyState from './EmptyState';
import OrganizationsList from './OrganizationsList';

interface SearchModalProps extends DialogProps {
  search: string;
  onClose: () => void;
  marketSlug?: any;
}

const useStyles = makeStyles()((theme) => ({
  rootContainer: {
    maxHeight: '100%',
    height: '762px',
  },
  dialog: {
    position: 'absolute',
    margin: 0,
    top: 0,
    borderRadius: 0,
  },
  searchContainer: {
    position: 'relative',
    padding: '20px 0 !important',
    top: -10,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    justifyContent: 'space-between',
  },
  searchInput: {
    lineHeight: 24,
    fontSize: 24,
    fontWeight: 600,
    fontFamily: 'Poppins',
    [theme.breakpoints.down('sm')]: {
      lineHeight: 'unset',
      fontSize: 15,
      fontWeight: 500,
    },
  },
  searchInputStyle: {
    maxWidth: 'calc(50% - 26px)',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset',
      width: 'calc(100% + 24px)',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      width: 'calc(100% + 24px)',
    },
    left: -12,
    '&.Mui-focused': {
      '&.MuiInput-underline:after': {
        border: '1px solid #7B8BAD',
        transform: 'scaleX(0)',
      },
    },
    '&.MuiInput-underline:after': {
      border: '1px solid #7B8BAD',
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid #7B8BAD',
    },
  },
  contentContainer: {
    padding: '32px 142px',
    [theme.breakpoints.down('md')]: {
      padding: '38px 137px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '38px 0px',
    },
  },
  emptyState: {
    paddingTop: '110px !important',
    [theme.breakpoints.down('md')]: {
      padding: '110px 0 0 0 !important',
    },
  },
  title: {
    [theme.breakpoints.up('sm')]: {
      padding: '8px 0 0 0',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '32px',
      textAlign: 'start',
      fontWeight: 700,
    },
  },
  titleText: {
    [theme.breakpoints.down('md')]: {
      fontWeight: 700,
      textTransform: 'none',
    },
  },
  dialogTitleWrapper: {
    padding: 0,
    '& .MuiGrid-container': {
      '& .MuiGrid-grid-xs-2': {
        display: 'none',
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 24px 0 24px',
    },
  },
  mobileTitle: {
    width: '90%',
    top: 12,
    position: 'relative',
    textTransform: 'uppercase',
  },
  closeButtonContainer: {
    position: 'relative',
    top: 37,
    right: 7,
    [theme.breakpoints.down('sm')]: {
      top: 12,
      right: -19,
    },
    zIndex: 100,
  },
  closeButton: {
    padding: 0,
  },
  listWrapper: {
    padding: '20px 0 !important',
  },
  bottom: {
    padding: '20px 0 !important',
    marginBottom: 250,
  },
  searchIcon: {
    top: 0,
    [theme.breakpoints.down('sm')]: {
      top: '3px !important',
    },
  },
}));

const SearchModal: React.FC<React.PropsWithChildren<SearchModalProps>> = (props) => {
  const { search, onClose, marketSlug, open } = props;
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'), { noSsr: true });
  const { classes } = useStyles();
  const [t] = useTranslation('public');
  const [queryParams, setQueryParams] = useState({ search });
  const [searchTerm, setSearchTerm] = useState(search);
  const [flag, setFlag] = useState(false);
  const [inputTimeout, setInputTimeout] = useState(null);

  useEffect(() => () => clearTimeout(inputTimeout as NodeJS.Timeout), [inputTimeout]);

  const hiddenEventsQueryParams = useMemo(
    () => ({
      exclude_hidden_events: true,
      search: queryParams.search,
      events_interval: false, // Exclude past Events
      page_size: 6,
      group_by_market_slug: marketSlug,
      ordering: 'session_date',
    }),
    [queryParams.search, marketSlug],
  );

  const hiddenOrganizationsQueryParams = useMemo(
    () => ({
      search: queryParams.search,
      page_size: 4,
    }),
    [queryParams.search],
  );

  const eventsStateSelector = useMemo(() => EventsSelectors.searchEventsState(), []);
  const organizationsStateSelector = useMemo(() => OrganizationsSelectors.searchOrganizationsState(), []);

  const { state: eventsState } = useLoader({
    actions: EventsActions.getSearchEvents,
    stateSelector: eventsStateSelector,
    options: {
      hiddenQueryParams: hiddenEventsQueryParams,
      queryParams,
      skipInitialLoad: true,
    },
  });

  const { state: organizationsState } = useLoader({
    actions: OrganizationsActions.getSearchOrganizations,
    stateSelector: organizationsStateSelector,
    options: {
      hiddenQueryParams: hiddenOrganizationsQueryParams,
      queryParams,
      skipInitialLoad: true,
    },
  });

  const handleSearch = (event: any) => {
    if (inputTimeout) clearTimeout(inputTimeout as NodeJS.Timeout);
    event.persist();
    const { value } = event.target;
    setSearchTerm(value);
    if (value.length > 2) {
      setInputTimeout(
        setTimeout(() => {
          setQueryParams({
            ...queryParams,
            search: value,
          });
        }, 500),
      );
    }
  };

  const handleSearchOnce = () => {
    if (!flag) {
      setFlag(true);
      if (search?.length > 2) {
        setQueryParams({
          ...queryParams,
          search,
        });
      }
    }
  };
  const display = open ? 'flex' : 'none';

  return (
    <div className={classes.rootContainer} style={{ display }}>
      <Dialog
        {...props}
        fullScreen={isMobile}
        classes={{ paper: classes.dialog }}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        PaperProps={
          isMobile
            ? {
                style: {
                  minWidth: '100%',
                  minHeight: '100%',
                  boxShadow: '0px 8px 56px rgba(56, 60, 68, 0.16)',
                },
              }
            : {
                style: {
                  minWidth: '100%',
                  height: 'auto',
                  maxHeight: '100%',
                  boxShadow: '0px 8px 56px rgba(56, 60, 68, 0.16)',
                },
              }
        }
      >
        <CustomDialogTitle className={classes.dialogTitleWrapper} />
        <DialogContent>
          <Grid container justifyContent="space-between">
            <Grid item className={classes.mobileTitle}>
              {isMobile && (
                <Typography align="center" variant="h5" color="primary">
                  {t<string>('searchHeader.search')}
                </Typography>
              )}
            </Grid>
            <Grid item className={classes.closeButtonContainer}>
              <IconButton className={classes.closeButton} onClick={onClose} size="large">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container className={classes.contentContainer}>
            <Grid item xs={12} className={classes.searchContainer}>
              <Input
                fullWidth
                inputRef={(input: any) => {
                  setTimeout(() => {
                    input?.focus();
                  }, 100);
                  handleSearchOnce();
                }}
                value={searchTerm}
                onChange={handleSearch}
                // placeholder={t<string>('searchHeader.placeholder')}
                className={classes.searchInputStyle}
                inputProps={{
                  className: classes.searchInput,
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <Image
                      className={classes.searchIcon}
                      src="/static/img/icon-search.png"
                      alt="icon-search"
                      width={26}
                      height={26}
                    />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid
              className={
                isMobile && eventsState?.entities?.length && !organizationsState.entities?.length
                  ? classes.bottom
                  : classes.listWrapper
              }
              item
              xs={12}
            >
              <RenderComponent
                isLoading={queryParams.search && isFetchingStates(eventsState)}
                renderContent={() => (
                  <EventsList
                    entities={eventsState.entities}
                    count={eventsState.count}
                    searchTerm={searchTerm}
                    onClose={onClose}
                  />
                )}
              />
            </Grid>
            <Grid
              className={isMobile && organizationsState.entities?.length ? classes.bottom : classes.listWrapper}
              item
              xs={12}
            >
              <RenderComponent
                isLoading={queryParams.search && isFetchingStates(organizationsState)}
                renderContent={() => (
                  <OrganizationsList
                    entities={organizationsState.entities}
                    onClose={onClose}
                    count={organizationsState.count}
                  />
                )}
              />
            </Grid>
            {!isFetchingStates(eventsState, organizationsState) &&
              eventsState.entities?.length === 0 &&
              organizationsState.entities?.length === 0 && (
                <Grid item xs={12} className={classes.emptyState}>
                  <EmptyState />
                </Grid>
              )}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SearchModal;
