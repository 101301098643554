import React from 'react';
import { Link, LinkProps, LinkTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
  },
}));

interface IProps extends LinkProps {
  label: string;
  href?: string;
  as?: string;
  underline?: 'none' | 'hover' | 'always';
  component?: React.ElementType;
  onClick?: () => void;
}

const HeaderLinkItem: React.FC<React.PropsWithChildren<IProps>> = ({
  label,
  href,
  as,
  color = 'inherit',
  component = 'a',
  onClick,
  ...props
}) => {
  const { classes } = useStyles();
  return (
    <Link
      {...props}
      component={component}
      classes={classes}
      href={href}
      as={as}
      color={color}
      variant="button"
      underline="none"
      onClick={onClick}
    >
      {label}
    </Link>
  );
};

export default HeaderLinkItem as OverridableComponent<LinkTypeMap<IProps>>;
