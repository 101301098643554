import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { InputBase } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import InputLimit from './InputLimit';

const useStyles = makeStyles()((theme) => ({
  root: {
    textAlign: 'center',
  },
  inputTitle: {
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid #D2D8EC`,
    padding: theme.spacing(0, 0, 1),
    '& textarea': {
      ...theme.typography.h6,
      textAlign: 'inherit',
      '&::placeholder': {
        opacity: 1,
        color: theme.palette.text.secondary,
      },
    },
  },
  inputTitleError: {
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    padding: theme.spacing(0, 0, 1),
    '& textarea': {
      ...theme.typography.h6,
      textAlign: 'inherit',
      '&::placeholder': {
        opacity: 1,
        color: theme.palette.secondary.light,
      },
    },
  },
}));

function InputTitle({ maxLength, value, helperText, ...props }) {
  const { classes } = useStyles();
  const shouldShowError = () => !value && helperText;

  return (
    <div className={classes.root}>
      <InputBase
        className={shouldShowError() ? classes.inputTitleError : classes.inputTitle}
        value={value}
        multiline
        inputProps={{
          maxLength,
        }}
        {...props}
      />
      <InputLimit value={value} maxLength={maxLength} errorText={shouldShowError() ? helperText : ''} />
    </div>
  );
}

InputTitle.propTypes = {
  maxLength: PropTypes.number.isRequired,
  value: PropTypes.string,
  helperText: PropTypes.string,
};

export default memo(InputTitle);
