import * as Yup from 'yup';
import React from 'react';
import withFormSubmission from 'components/common/withFormSubmission';
import { MergedProps } from 'components/common/RuntimeForm';

export interface CreateEventModalFormValues {
  title: string;
}

export interface CreateEventModalFormProps {
  disabled: boolean;
  onSubmit: (values: CreateEventModalFormValues) => void;
  onClose: (e: React.MouseEvent) => void;
}

export type FormComponentProps = MergedProps<CreateEventModalFormProps, CreateEventModalFormValues>;

const withCreateEventForm = withFormSubmission<CreateEventModalFormProps, CreateEventModalFormValues>({
  validationSchema: Yup.object({
    title: Yup.string().trim().max(100).required(),
  }),
  mapPropsToValues: () => ({
    title: '',
  }),
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
});

export default withCreateEventForm;
