import React from 'react';
import { MenuItem as MuiMenuItem, MenuItemProps as MuiMenuItemProps, Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { capitalize } from '@mui/material/utils';

const useStyles = makeStyles<void, 'selected'>()((theme: Theme, _params, classes) => ({
  root: {
    minHeight: 56,
    [theme.breakpoints.up('sm')]: {
      minHeight: 56,
    },
    '&.justifyContentEnd': {
      justifyContent: 'end',
    },
    '&.justifyContentStart': {
      justifyContent: 'start',
    },
    '&.small': {
      minHeight: 40,
      height: 40,
      [theme.breakpoints.up('sm')]: {
        minHeight: 40,
        height: 40,
      },
    },
    [`&.multiple.${classes.selected}`]: {
      background: 'transparent',
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.07),
        color: theme.palette.primary.main,
      },
    },
  },
  selected: {},
}));

interface MenuItemProps extends MuiMenuItemProps {
  justifyContent?: 'end' | 'start';
  multiple?: boolean;
  small?: boolean;
}

type TypeMap<D extends React.ElementType = 'li'> = {
  props: MenuItemProps;
  defaultComponent: D;
  classKey: string;
};

const MenuItem: React.FC<React.PropsWithChildren<MenuItemProps>> = (props) => {
  const { justifyContent = 'start', className, multiple, small } = props;
  const { classes, cx } = useStyles();

  return (
    <MuiMenuItem
      {...(props as any)}
      className={cx(`justifyContent${capitalize(justifyContent)}`, className, { multiple, small })}
      classes={classes}
    />
  );
};

export default MenuItem as OverridableComponent<TypeMap>;
