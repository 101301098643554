import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'i18n';
import SuccsessfullIcon from 'components/svg/SuccsessfullIcon.svg';
import CloseButton from './CloseButton';

const useStyles = makeStyles()((theme) => ({
  root: {
    height: 352,
    width: 288,
    padding: '36px 0 0 40px',
    background: '#fff',
    borderRadius: 4,
    boxShadow: '0px 8px 32px 0px #22265229',
    [theme.breakpoints.down('sm')]: {
      width: 288,
    },
  },
  closeBtn: {
    color: '#3753D7',
    top: 16,
    right: 16,
    left: 'unset',
  },
  mainImage: {
    position: 'relative',
    top: -24,
    left: 36,
  },
  title: {
    position: 'relative',
    top: -24,
    left: 36,
  },
  description: {
    textAlign: 'center',
    position: 'relative',
    top: -24,
    left: -16,
  },
}));

interface IProps {
  onClose: any;
}

const SuccessfullyCreatedAccountPopoverContainer: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { onClose } = props;
  const { classes } = useStyles();
  const [t] = useTranslation('public');

  return (
    <Grid container className={classes.root}>
      <CloseButton className={classes.closeBtn} onClose={onClose} />
      <Grid item xs={12}>
        <SuccsessfullIcon className={classes.mainImage} />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.title} style={{ fontSize: 28 }} variant="h4">
          {t<string>('createdAccountPopover.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.description} variant="body1">
          {t<string>('createdAccountPopover.description')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SuccessfullyCreatedAccountPopoverContainer;
