import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'i18n';
import { useSelector } from 'react-redux';
import { AuthSelectors } from 'store/selectors';
import CloseButton from './CloseButton';

const useStyles = makeStyles()((theme) => ({
  root: {
    height: 304,
    width: 265,
    padding: '36px 0 0 40px',
    borderRadius: 16,
    background: '#3753D7',
    backgroundImage: 'url(/static/svg/welcomeBg.svg)',
    backgroundPosition: '-8px 0',
    [theme.breakpoints.down('sm')]: {
      width: 288,
    },
  },
  item: {
    lineHeight: 22,
    maxWidth: 195,
  },
  buttonLetsGo: {
    '&.MuiButton-root': {
      background: '#fff',
      borderRadius: 16,
      width: 155,
      height: 32,
      fontSize: 13,
      fontWeight: 500,
    },
  },
  buttonMaybeLater: {
    '&.MuiButton-root': {
      color: '#fff',
      width: 155,
      height: 32,
      fontSize: 13,
      fontWeight: 500,
    },
  },
  closeBtn: {
    color: '#FFF',
    top: 16,
    right: 16,
    left: 'unset',
  },
}));

interface IProps {
  onClose: any;
  onOpenModal?: any;
}

const WelcomePopoverContainer: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { onClose, onOpenModal } = props;
  const { classes } = useStyles();
  const [t] = useTranslation('public');
  const currentUser = useSelector(AuthSelectors.currentUser);

  return (
    <Grid container className={classes.root}>
      <CloseButton className={classes.closeBtn} onClose={onClose} />
      <Grid item className={classes.item} style={{ paddingBottom: 10 }}>
        <Typography style={{ lineHeight: '20px' }} variant="h6" color="white">
          {t<string>('welcomePopup.greeting', { name: currentUser?.first_name })}
        </Typography>
      </Grid>
      <Grid item className={classes.item} style={{ paddingBottom: 2 }}>
        <Typography style={{ lineHeight: '20px' }} variant="h6" color="white">
          {t<string>('welcomePopup.letsPersonalize')}
        </Typography>
      </Grid>
      <Grid item className={classes.item} style={{ paddingBottom: 10 }}>
        <Typography style={{ lineHeight: '16px' }} variant="body2" color="white">
          {t<string>('welcomePopup.shareAboutYourself')}
        </Typography>
      </Grid>
      <Grid item className={classes.item}>
        <Typography variant="body2" color="white">
          {t<string>('welcomePopup.itTake1minute')}
        </Typography>
      </Grid>
      <Grid item style={{ marginRight: 40, width: '100%' }}>
        <div style={{ paddingTop: 12, display: 'flex', width: '100%', justifyContent: 'center' }}>
          <Button
            className={classes.buttonLetsGo}
            onClick={() => {
              onClose();
              if (onOpenModal) onOpenModal();
            }}
          >
            {t<string>('welcomePopup.letsGo')}
          </Button>
        </div>
        <div style={{ paddingTop: 8, paddingBottom: 8, display: 'flex', width: '100%', justifyContent: 'center' }}>
          <Button className={classes.buttonMaybeLater} onClick={onClose}>
            {t<string>('welcomePopup.maybeLater')}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default WelcomePopoverContainer;
