import { createTheme } from '@mui/material/styles';
import overrides from './overrides';
import palette from '../palette';
import typography from '../typography';
import mixins from '../mixins';
import breakpoints from '../breakpoints';

export default createTheme({
  palette,
  typography,
  components: overrides,
  mixins,
  breakpoints,
});
