import React, { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LanguageIcon from '@mui/icons-material/Language';
import { ButtonBase, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ExpandIconCheckbox from 'components/common/ExpandIconCheckbox';

import { HeaderMenu } from '../HeaderItems';

interface SwitchLanguageListProps {
  children: React.ReactNode;
  title: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
  icon: {
    marginRight: theme.spacing(0.5),
  },
}));

const SwitchLanguageList: React.FC<React.PropsWithChildren<SwitchLanguageListProps>> = (props) => {
  const { children, title } = props;
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (e: React.MouseEvent) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid container alignItems="center" wrap="nowrap">
        <ButtonBase disableRipple onClick={handleOpen}>
          <LanguageIcon className={classes.icon} fontSize="small" />
          <Typography style={{ fontWeight: 500 }} variant="body2" textTransform="uppercase">
            {title}
          </Typography>
          <ExpandIconCheckbox style={{ height: 24, width: 24 }} checked={open} IconComponent={ArrowDropDownIcon} />
        </ButtonBase>
      </Grid>
      <HeaderMenu open={open} onClose={handleClose} anchorEl={anchorEl}>
        {children}
      </HeaderMenu>
    </>
  );
};

export default SwitchLanguageList;
