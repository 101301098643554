import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IProps {
  value?: string;
  maxLength: number;
  className?: string;
  errorText?: string;
}

const useStyles = makeStyles()((theme) => ({
  limit: {
    color: theme.palette.text.secondary,
  },
  error: {
    color: theme.palette.secondary.light,
    fontWeight: 600,
    fontSize: 11,
    lineHeight: '120%',
  },
}));

const InputLimit: React.FC<React.PropsWithChildren<IProps>> = ({ value = '', maxLength, className, errorText }) => {
  const { classes } = useStyles();
  return (
    <Typography variant="caption" color="textPrimary" className={className}>
      {errorText && <span className={classes.error}>{errorText}</span>}
      {!errorText && (
        <>
          {value.length} <span className={classes.limit}>/ {maxLength} symbols</span>
        </>
      )}
    </Typography>
  );
};

export default InputLimit;
