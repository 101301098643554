import React, { forwardRef } from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { IconButton } from '@mui/material';

interface IProps {
  onOpen: () => void;
}

const DateIconButton = forwardRef<HTMLButtonElement, IProps>(({ onOpen }, ref) => (
  <IconButton size="small" ref={ref} onClick={onOpen}>
    <CalendarTodayIcon color="primary" fontSize="small" />
  </IconButton>
));

export default DateIconButton;
