import React, { memo } from 'react';
import { CircularProgress, MenuItem, Select, SelectProps } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { TFunction } from 'next-i18next';
import headerSelect from 'theme/headerSelect';
import { Market } from 'api/admin/models';

interface IProps {
  t: TFunction;
  options: Market[];
  isFetching: boolean;
  onChange: SelectProps['onChange'];
  value: string;
}

const useStyles = makeStyles()((theme) => ({
  select: {
    fontSize: 13,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: '#5FC6B9',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: 179,
      '.MuiOutlinedInput-input': {
        padding: 8,
        paddingRight: '8px !important',
      },
    },
  },
  icon: {
    margin: 'auto',
  },
  arrow: {
    color: '#5FC6B9',
    marginRight: -6,
  },
  menuItem: {
    padding: '12px 16px',
    textAlign: 'left',
    borderRadius: 8,
    '&.Mui-selected': {
      backgroundColor: 'unset',
    },
    '&:hover': {
      color: '#5FC6B9',
      textDecoration: 'none',
      backgroundColor: 'rgba(95, 198, 185, 0.2) !important',
    },
    [theme.breakpoints.down('md')]: {
      width: 179,
    },
  },
}));

const AdminMarketSelect: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { t, options, onChange, value, isFetching } = props;
  const { classes } = useStyles();

  return (
    <StyledEngineProvider>
      <ThemeProvider theme={headerSelect}>
        <Select
          sx={{
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
          }}
          disabled={isFetching}
          classes={{
            icon: classes.arrow,
          }}
          className={classes.select}
          fullWidth
          displayEmpty
          variant="outlined"
          onChange={onChange}
          value={value}
          MenuProps={{
            autoFocus: false,
            variant: 'menu',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: -20,
              horizontal: 'right',
            },
          }}
        >
          {options?.length > 1 && (
            <MenuItem className={classes.menuItem} value="">
              {isFetching ? (
                <CircularProgress size={18} className={classes.icon} />
              ) : (
                t<string>('adminHeader.allMarkets')
              )}
            </MenuItem>
          )}
          {options.map((market) => (
            <MenuItem className={classes.menuItem} value={String(market.id)} key={market.id}>
              {market.title}
            </MenuItem>
          ))}
        </Select>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default memo(AdminMarketSelect);
